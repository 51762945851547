import React from "react"
import Head from "../components/head"
import Layout from "../components/layout"
import ContactForm from "../components/index/contact-form"

export default class About extends React.Component {
  render() {
    return (
      <>
        <Head title={"Contact Us"} />
        <Layout activePage="Contact">
          <ContactForm />
        </Layout>
      </>
    )
  }
}
