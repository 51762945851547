import React from "react"
import { validateEmail } from "../../utils/validators";

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.handleClear = this.handleClear.bind(this)
    this.handleContact = this.handleContact.bind(this)
    this.state = { 
      email: "", 
      name: "", 
      message: "",
      validEmail: true
    }
  }

  handleClear(e) {
    this.setState({ email: "", name: "", message: "" })
  }
  handleChange({ target: { name, value } }) {
    this.setState({validEmail:true })
    if (name === "emailAddress") {
      //validate first
      if (!validateEmail(value)) {
        this.setState({validEmail: false});
      }
      this.setState({ email: value });
    }
    if (name === "name") {
      this.setState({ name: value });
    }
    if (name === "message") {
      this.setState({ message: value });
    }
  }

  handleContact() {
    //do the sign up request
  }

  render() {
    return (
      <div className="mx-auto w-full md:w-5/12 space-y-4 flex flex-col mb-16">
        <h2 className="text-center">Contact Us</h2>
        <div className="flex flex-col">
          <label className="mb-2" htmlFor="name">
            Name
          </label>
          <input className="input-field" name="name" value={this.state.name} 
                onBlur={this.handleChange}
                onChange={this.handleChange}/>
        </div>
        <div className="flex flex-col">
          <label className="mb-2" htmlFor="emailAddress">
            Email Address
          </label>
          <input
            className="input-field"
            name="emailAddress"
            value={this.state.email}
            onBlur={this.handleChange}
            onChange={this.handleChange}
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-2" htmlFor="message">
            Write your message
          </label>
          <textarea className="input-field" name="message"
                onBlur={this.handleChange}
                onChange={this.handleChange}></textarea>
        </div>
        <div className="text-center">
          {/* <button onClick={this.handleClear}>Reset</button> */}
          <button onClick={this.handleContact} className="btn-primary">
            Submit
          </button>
        </div>
      </div>
    )
  }
}
